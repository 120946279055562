.details {
  width: 100%;
  height: 100%;
  background: linear-gradient(#1d3649, #152935);
  position: relative;
}

.details-title {
  width: 100%;
  height: 7%;
  line-height: 4vh;
  background: linear-gradient(#1d3649, #152935);
  border-top: 1px solid #325c80;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  overflow-x: scroll;
  padding-left: 5px;
  border-radius: 7px;
}

/* .details-title h2:nth-child(1) {
  color: #e1e1e1;
  width: 150px;
  text-align: center;
} */

.details-title h2 {
  font-size: 1.8vh;
  padding: 0 2vh;
  font-weight: bold;
  /* margin-right: 10px; */
  color: #aeaeae;
  transition: all 0.4s ease-in;
  margin-right: 3px;
  white-space: nowrap;
}

.details-title h2:hover {
  cursor: pointer;
  background-color: #d74108;
  color: white;
  border-radius: 10px;
}

.details-title .active {
  background-color: #d74108;
  color: white;
  border-radius: 10px;
}

.container-contents {
  width: 100%;
  height: 93%;
  display: flex;
}

.detail-card {
  height: 100%;
  /* width: 20%; */
  flex: 1.2;
  overflow-y: scroll;
  /* position: fixed; */
  padding-left: 1vh;
}

.detail-card p {
  /* font-size: 14px; */
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  color: grey;
  height: 100%;
}

.detail-agenda {
  flex: 1;
  height: 100%;
  padding: 1vh;
  animation: moveAgenda 2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.title-detail-agenda {
  background: linear-gradient(#1d3649, #152935);
  border-left: 5px solid #00a6a0;
  border-top: 1px solid #325c80;
  border-radius: 5px;
  margin-bottom: 5px;
}

.title-detail-agenda h3 {
  font-size: 1.75vh;
  font-family: "Merriweather", serif;
  line-height: 2.5vh;
  padding: 1vh 1.5vh;
  font-weight: bold;
  color: #c7c7c7;
}

.content-detail-agenda {
  font-family: "Arial", serif;
  padding: 1vh 1.5vh;
  color: #c7c7c7;
}

.content-detail-agenda h3 {
  font-size: 2vh;
  font-family: "Merriweather", serif;
  line-height: 2.5vh;
  padding: 1vh 0;
  font-weight: bold;
  color: #a0a0a0;
}
.content-detail-agenda p {
  font-size: 2.7vh;
  font-family: "Arial", serif;
  padding: 1vh 0;
  font-weight: bold;
  color: #e0e0e0;
  margin-bottom: 30px;
}

::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb:hover {
  background-color: rgba(26, 22, 22, 0.5);
}

/* Animation */

@keyframes moveAgenda {
  0% {
    flex: 0;
  }
  100% {
    flex: 1;
  }
}

/* Mobile */
@media (max-width: 768px) {
  .details {
    width: 100%;
    height: 40%;
    background: linear-gradient(#1d3649, #152935);
    position: relative;
  }

  .detail-card {
    height: 367px;
  }

  .details-title {
    width: 100%;
    height: 15%;
  }
  .title-detail-agenda h3 {
    font-size: 1.2vh;
  }
  .content-detail-agenda h3 {
    font-size: 1vh;
    padding: 0;
  }
  .content-detail-agenda p {
    font-size: 1.7vh;
    padding: 0;
  }
}
