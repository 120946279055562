.home {
  background: linear-gradient(#1d3649, #152935);
  width: 100vw;
  height: 100vh;
  position: relative;
}

.main-content {
  display: flex;
  width: 100%;
  height: 85vh;
}

.item-content {
  width: 50%;
}

.right-content {
  width: 100%;
  height: 43.2%;
  position: relative;
  background: radial-gradient(#264a60, #1d3649);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  overflow: hidden;
}

.footer {
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.2vh;
  position: relative;
}

.medsos-ct {
  position: absolute;
  right: 10px;
}

/* Mobile */
@media (max-width: 768px) {
  .home {
    width: 100%;
  }

  .main-content {
    flex-wrap: wrap;
    margin-top: 2px;
    display: block;
  }

  .right-content {
    width: 100%;
    display: block;
  }

  .item-content {
    width: 100%;
  }

  .footer {
    position: fixed;
    bottom: 0;
    background: linear-gradient(#1d3649, #152935);
    width: 100%;
    font-size: 12px;
  }
}
