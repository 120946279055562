.topics {
  height: 100%;
  width: 49.5%;
}

.topic-header {
  width: 100%;
  height: 4.25vh;
  line-height: 4vh;
  background: linear-gradient(#1d3649, #152935);
  border-top: 1px solid #325c80;
  border-radius: 7px;
}

.cloudTabSelected {
  font-size: 2.25vh;
  font-weight: bold;
  padding-right: 0.5vh;
  padding-left: 1vh;
  color: #e0e0e0;
}

.topic-cloud {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background: radial-gradient(#264a60, #1d3649);
  padding: 7px 12px;
  position: relative;
}

.topic-cloud article {
  font-size: 22px;
  font-weight: 600;
  color: rgb(78, 78, 254);
  text-align: center;
  margin-bottom: 12px;
}

.topic-cloud article span {
  font-size: 16px;
  color: white;
}

.topic-cloud p {
  font-size: 12px;
  color: rgb(187, 187, 187);
}

/* quote */
.wrapper-quote {
  padding: 10px 35px;
}

.wrapper-quote q {
  font-size: 14px;
  font-style: italic;
}

.wrapper-quote p {
  font-size: 12px;
  margin-top: 12px;
}

/* Mobile */
@media (max-width: 768px) {
  .topics {
    width: 100%;
    height: 150px;
    margin-bottom: 30px;
  }
}
